.initial_image_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    background-image: linear-gradient(to bottom ,  #1F2551, #003795 , transparent) , url('https://i.pinimg.com/originals/13/18/e1/1318e11aee7e8d6c9a9bf0f6368873a9.gif');
    background-position: center;
    background-size: cover;

}

.query_line{
    font-size: 16px;
    font-family: 'Cantarell', sans-serif;
    color : white;
}

.getAppBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0944ac;
    color: white;
    width : 160px ;
    height: 40px;
    padding: 5px;
    cursor: pointer;
    font-family: 'Cantarell', sans-serif;
    border-radius: 40px;
    outline: none;
    border :none;
    font-size : 16px ; 
    margin-top : 10px;
    transition: box-shadow 0.3s linear ; 
}

.getAppBtn:hover{
    box-shadow: 0px 0px 10px #143b7c;
}
