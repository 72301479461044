.chat-container{
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.chatLeftSection{
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    background-color: #d9deff;
    border-right: 1px solid rgba(81, 81, 81, 0.5);
}

.tenPointQuerySupportContainer{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    padding:10px 10px 0px 10px;
    background-color:#253287 ;
}

.chatVideo{
    position: relative;
}

.rightChatVideo{
    width: 200px;
    height: 195px;
    object-fit: fill;
}

.videoPlayBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

.tenPointLogoNameCont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.tenPointLogoNameInfo{
    display: flex;
    align-items: center;
    width: 80%;
}

.tenPointOtherOptionCont{
    display: flex;
    justify-content: flex-end;
    width: 20%;
}

.refreshIcon{
    font-size: 24px;
    color: white;
    cursor: pointer;
    padding: 2px;
    border-radius: 50%;
}

.refreshIcon:hover{
    background-color: white;
    color: black;
    animation: rotateRefreshIcon 0.5s linear;
}

@keyframes rotateRefreshIcon {
    0%{
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}

.searchInputContainer{
    display: flex;
    justify-content:space-evenly;
    width: 100%;
    color : black;
    font-weight: 500;
    border-radius: 20px;
    background-color: white;  
    margin-bottom: 20px;
}

.searchInput{
    width: 80%;
    border: none;
}

.search{
    /* font-family: 'Cantarell', sans-serif; */
    font-family: 'Cantarell', sans-serif;
    font-size: 16px;
    width: 100%;
    border: none;
    outline: none;
    padding: 7px 5px;
}

.searchBtnCont{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
}

.searchBtn{
    border: none;
    cursor: pointer;
    background: transparent;
    outline: none;
}

.closeBtnContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    cursor: pointer;
}

.TenPointName{
    font-size: 16px;
    /* font-family: 'Cantarell', sans-serif; */
    font-family: 'Cantarell', sans-serif;
    margin: 0px 0px 0px 10px;
    color: #FFF;
    font-weight: 400;
}

.chatRightSection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    height: 100%;
    background: #1f2551;
}

.userQueryListContainer{
    width: 100%;
    height: 100%;
    display: block;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    background-color: #1f2551;
    cursor: pointer;
}

.userQueryListContainer::-webkit-scrollbar{
    width: 5px;
}

.userQueryListContainer::-webkit-scrollbar-track{
    background: transparent;   
}

.userQueryListContainer::-webkit-scrollbar-thumb{
        background: gray;
}

.userQueryList{
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 5px;
}

.userQueryList:hover{
    background-color: #101951;
}

.changeBackQuery{
    background-color: #1a2674;
    
}

.userImageAndNameCont{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userProfileImageInChatInfo{
    background-color: transparent;
    height:50px;
    width:50px;
    border-radius: 50%;
}

.userLastMessageContainer{
    width: 80%;
    height:100%;
}

.nameAndUnseenMsgCont{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
}

.userNameQuery{
    font-family: 'Cantarell', sans-serif;
    font-size: 16px;
    width: 65%;
    color: white;
    margin-bottom: 0px;
}

.userMessage{
    width: 60%;
    display: flex;
    align-items: center;
}

.userLastMessageInfo{
    display: flex;
    align-items: center;
    height: 50%;
    margin: 0px;
}

.lastMessage{
    font-family: 'Cantarell', sans-serif;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #bcbcbc;
    margin: 0px;
}

.userTimeAndUnseenMessageCont{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.lastMessagedeliverDate{
    font-family: 'Cantarell', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #bcbcbc;
}

.userLastMessage{
    font-size: 12px;
    margin-top: 0px;
    color: rgb(101, 101, 101);
}


.chatHeaderContainer{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #253287;
}

.chatHeaderCanvasContainer{
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #253287;
}

.userImagePlaceholderContainer{
   width : 30px;
   height:30px;
   background-position : center ;
   background-size :cover ;
   border-radius : 50% ;
   border: 2px solid white;
   padding: 5px;
}
.chatUserName{
    font-family: 'Cantarell', sans-serif;
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin:0px 0px 0px 10px;
}

.chatBodyContainer{
    margin-top: 2px;
    width: 100%;
    height: 80%;
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color:linear-gradient( to right , #253287, #1f2551);
}

.chatBodyCanvasContainer{
    margin-top: 2px;
    width: 100%;
    height: 86%;
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-image:url("https://w0.peakpx.com/wallpaper/580/650/HD-wallpaper-whatsapp-bg-dark-background-thumbnail.jpg");
    background-position: center;
    background-size: cover;
}

.chatBodyContainer::-webkit-scrollbar{
    width: 5px;
}

.chatBodyContainer::-webkit-scrollbar-track{
    background: transparent;   
}

.chatBodyContainer::-webkit-scrollbar-thumb{
        background: gray;
}

.chatStartDateContainer{
    width :100% ;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
   
}

.chatStartDate{
    font-family: 'Cantarell', sans-serif;
    font-size: 12px;
    padding: 5px;
     margin: 0px;
     background-color: white;
     border-radius: 10px;
    color: black;
}

.rightSideChatContainer{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    height: fit-content;
    margin-bottom: 10px;
}

.leftSideChatContainer{
    display: flex;
    width: 100%;
    height: fit-content;
    margin-bottom: 10px;
}

.messageInfoContainerRight{
    max-width: 90%;
    width: fit-content;
    height: fit-content;
    background-color: #253287;
    padding: 6px 8px 8px 9px;
    border-radius: 7.5px;
}

.messageInfoContainerLeft{
    max-width: 90%;
    width: fit-content;
    height: fit-content;
    background-color: #2d3570;
    padding: 6px 8px 8px 9px;
    border-radius: 7.5px;
}

.SentByName{
    font-family: 'Cantarell', sans-serif;
    color: orange;
    text-transform: capitalize;
    font-size: 12px;
    margin: 0px 0px 0px -2px;
    display: flex;
    justify-content: space-between;
}

.userSentByName{
    font-family: 'Cantarell', sans-serif;
    text-transform: capitalize;
    font-size: 12px;
    color: rgb(0, 206, 0);
    margin: 0px 0px 0px -2px;
}

.message{
    font-family: 'Cantarell', sans-serif;
    color: white;
    font-size: 14px;
    word-break: break-all;
    margin: 0px 0px 2px 0px;
}

.messageTime{
    font-family: 'Cantarell', sans-serif;
    color: #c7c7c7;
    font-size: 8px;
    display: flex;
    justify-content: flex-end;
    margin: 2px 0px 0px 0px;
}

.messageMediaTime{
    font-family: 'Cantarell', sans-serif;
    color: #c7c7c7;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 8px;
    margin: 2px 0px 0px 0px;
}

.messageTimeMedia{
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    color: rgb(101, 101, 101);
}

.deleteMsgContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: rgba(128, 128, 128, 0.203);
    border: 1px solid rgb(206, 206, 206);
    cursor: pointer;
    border-radius: 50%;
    padding: 2px;
}

.deleteIcon{
    color: white;
}

.deletedMessage{
    font-family: 'Cantarell', sans-serif;
    font-size: 14px;
    color: gray;
    display: flex;
    align-items: center;
    margin: 0px 0px 2px 0px;
}

.chatFooterContainer{
    width: 100%;
    height: 10%;
    padding: 5px;
    background-color: #253287;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatFooterCanvasContainer{
    width: 100%;
    height: 10%;
    padding: 5px;
    background-color: #253287;
    display: flex;
    justify-content: center;
    align-items: center;
}

.messageInput{
    width: 80%;
    height: 60px;
    font-family: 'Cantarell', sans-serif;
    color: white;
    border:none ;
    padding: 10px;
    outline: none;
    border-radius: 8px;
    background: #5250cb73;
    overflow: hidden;
    resize: none ;
}

.sendMessageBtn{
    width: 60px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-size: 14px;
    border-radius: 4px;
    background-color:  #475BD8;
    color: white;
    padding: 5px;
    border: none;
    outline: none; 
}

/* .sendMessageArrow:hover{
   transform: translateX(10px);
} */

.user_message{
    margin-left: 10px;
    color: rgb(112, 112, 112);
}

.deliverTime{
    font-size: 10px;
}

.unseenMessageCount{
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    padding: 5px;
    color: white;
    border-radius: 50%;
    background-color: #475BD8;
    margin: 0px;
}

.chatAttachment{
    display: none;
}

.chatFileButton{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 18px;
    border-radius: 50%;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    transition: background-color  0.3s ease-in-out;
}

.chatFileButton:hover{
    background-color:  #475BD8;
}

.backArrowBtnContainer{
    width: 12%;
    color: white;
    padding-right: 5px;
    padding-left: 5px;
    height: 40px;
    display: flex;
    align-items: center;

}

.backArrowBtn{
    font-size: 20px;
}

.currentTabStatus{
    width: 100%;
    height: 30px;
    display: flex;

    justify-content: center;
    align-items: center;
}

.chatTabContainer{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid white;
    cursor: pointer;
}

.updateTabContainer{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.tabName{
    font-family: 'Cantarell', sans-serif;
    color: white ;
    font-size: 16px;
    font-weight: 400;
    margin: 0px;
}

.offcanvas.offcanvas-bottom{
    border: none !important;
}

.offcanvas{
    background-color: #253287 !important;
}

.canvaContainer{
    display: none;
}

.spinnerContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width : 440px) {
    
    .chatRightSection{
        display: none;
    }

    .chatLeftSection{
        width: 100%;
    }

    .userProfileImageInChatInfo{
        width: 40px;
        height: 40px;
    }

    .canvaContainer{
        display: block;
    }

    .chatLeftSection{
        border: none;
    }
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root{
    min-width: fit-content !important;
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root{
    background-color: #1f2551 !important;
}