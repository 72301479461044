 @import "normalize.css";
html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.app-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
