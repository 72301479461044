
.customModal{
    position: fixed;
    left : 0;
    top: 0 ;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.3s all ease-in-out;
    pointer-events: none;
  
  }
  
  .customModal.show{
    opacity: 1;
    pointer-events: visible;
  }
  
  .customModalContent{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
    transform: translateY(-200px);
    transition: 0.3s all ease-in-out;
    border-radius: 10px;
  }
  
  .customModal.show .customModalContent{
    transform: translateY(0);
  }
  
  .customModalBody{
    display: flex;
    justify-content: center;
    align-items: center;
    width:auto;
    height: auto;
  }
  
  .modalBodyMediaVideo{
    width:100%;
    height:100% ;
    max-height : 400px;
  }

  .customModalFooter{
    margin:10px 0px;
  }

  .customModalBtn{
    color: white;
    width: 70px;
    height: 30px;
    border: none;
    background-color:  #475BD8;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    outline: none;
    border-radius:5px;
  }

   .modalTitle{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
     color : white;
   }